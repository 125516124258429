import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
}));

const PageTitle: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.title}>
      {props.children}
    </Typography>
  );
};

export default PageTitle;
