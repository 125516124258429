import { PageFilter } from '../state/app';
import { OpportunityItemListFilter } from '../state/opportunityItem';
import axios, { getResponseFileName } from '../utils/axios';
import { IOpportunityItem } from '../models/opportunityItem.model';
import { saveAs } from 'file-saver';
import { buildUrlSearchParams } from '../utils/apiRequestUtil';

const baseUrl = `/api/opportunityItems`;

export const createOpportunityItem = async (opportunityItem: IOpportunityItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.post(requestUrl, opportunityItem);
};

export const fetchOpportunityItemList = async ({
  filters,
  paging,
}: {
  filters: OpportunityItemListFilter;
  paging: PageFilter;
}) => {
  const params = buildUrlSearchParams(filters, paging);
  const requestUrl = `${baseUrl}`;

  return await axios.get(`${requestUrl}?${params.toString()}`);
};

export const fetchOpportunityItem = async (id: IOpportunityItem['id']) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.get(requestUrl);
};

export const deleteOpportunityItem = async (id: number) => {
  const requestUrl = `${baseUrl}/${id}`;
  return await axios.delete(requestUrl);
};

export const updateOpportunityItem = async (opportunityItem: IOpportunityItem) => {
  const requestUrl = `${baseUrl}`;
  return await axios.put(requestUrl, opportunityItem);
};

export const exportOpportunityItemsAsExcel = async (
  filters: OpportunityItemListFilter,
) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  const response = await axios.get(`${requestUrl}/export/excel?${params.toString()}`, {
    responseType: 'blob',
  });
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};
export const exportOpportunityItemsAsExcelWithSampleDevelopmentRequestForm = async (
  filters: OpportunityItemListFilter,
) => {
  const params = new URLSearchParams();
  params.append('cacheBuster', new Date().getTime().toString());
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  const requestUrl = `${baseUrl}`;
  const response = await axios.get(
    `${requestUrl}/export/excelWithDevelopmentRequestForm?${params.toString()}`,
    {
      responseType: 'blob',
    },
  );
  const fileName = getResponseFileName(response);
  saveAs(response.data, fileName);
};
