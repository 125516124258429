import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IOpportunityItem } from '../../../models/opportunityItem.model';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearOpportunityItem,
  fetchOpportunityItemList,
  OpportunityItemListFilter,
  OpportunityItemState,
} from '../../../state/opportunityItem';
import { TaskState } from '../../../state/task';
import { selectedTaskItems, TaskItemState } from '../../../state/taskItem';
import { SearchField, SortLabel, TableLoading } from '..';
import { useTableSorting } from '../../hooks';
import useStyles from './taskItemChooseDialog.style';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { TablePageSize } from '../../../constants';

interface TaskItemChooseDialogProps {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />
  );
});

const TaskItemChooseDialog: React.FC<TaskItemChooseDialogProps> = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [sort, setSort] = useTableSorting(
    {
      orderBy: 'Opportunity.AssignmentNumber',
      direction: 'desc',
    },
    true,
  );
  const [filters, setFilters] = useState<OpportunityItemListFilter>({});
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });
  const { selectedItems } = useSelector<AppState, TaskItemState>(
    (state) => state.taskItem,
  );
  const initSelectedOpportunityItems = useMemo(
    () =>
      selectedItems.map((selectedItem) => {
        return selectedItem.opportunityItem!;
      }),
    [selectedItems],
  );
  const [selectedOpportunityItems, setSelectedOpportunityItems] = useState<
    IOpportunityItem[]
  >(initSelectedOpportunityItems);
  const {
    items: opportunityItems,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityItemState>((state) => state.opportunityItem);

  const {
    item: { id: taskId },
  } = useSelector<AppState, TaskState>((state) => state.task);

  useEffect(() => {
    const newPaging = { ...paging, sort };
    dispatch(fetchOpportunityItemList(filters, newPaging));
    return function cleanUp() {
      dispatch(clearOpportunityItem());
    };
  }, [dispatch, paging, filters, sort]);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      // disableBackdropClick={true}
      TransitionComponent={Transition}
    >
      <DialogTitle>Select Assignment Item</DialogTitle>
      <DialogContent>
        <div className={classes.filterContainer}>
          <SearchField
            label="Assignment Number"
            onChange={handleChangeFilter}
            filterKey="Opportunity.AssignmentNumber.startsWith"
            filters={filters}
          />
          <SearchField
            label="Program"
            onChange={handleChangeFilter}
            filterKey="Opportunity.Program.Name.startsWith"
            filters={filters}
          />
          <SearchField
            label="Brand"
            onChange={handleChangeFilter}
            filterKey="Opportunity.Brand.Name.startsWith"
            filters={filters}
          />
          <SearchField
            label="Block No"
            onChange={handleChangeFilter}
            filterKey="Block.BlockNo.startsWith"
            filters={filters}
          />
          <SearchField
            label="Item"
            onChange={handleChangeFilter}
            filterKey="Block.Item.startsWith"
            filters={filters}
          />
          {/* <SearchField
            label="Assignee"
            onChange={handleChangeFilter}
            filterKey="Assignee.FullName.Contains"
            filters={filters}
          /> */}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                    if (checked) {
                      const isItemAdded = (opItem: IOpportunityItem) => {
                        return (
                          selectedOpportunityItems.findIndex(
                            (selectedOpportunityItem) =>
                              selectedOpportunityItem.id! === opItem.id,
                          ) === -1
                        );
                      };
                      const itemToAdd = opportunityItems.filter(isItemAdded);
                      setSelectedOpportunityItems([
                        ...selectedOpportunityItems,
                        ...itemToAdd,
                      ]);
                    } else {
                      setSelectedOpportunityItems([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell>No.</TableCell>
              <TableCell>
                <SortLabel
                  sort={sort}
                  handleSortChange={setSort}
                  field="Opportunity.AssignmentNumber"
                >
                  Assignment Number
                </SortLabel>
              </TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field="Block.BlockNo">
                  Block No
                </SortLabel>
              </TableCell>
              <TableCell>Item XXXXX</TableCell>
              {/* <TableCell>Sample Dev</TableCell>
              <TableCell>NPD</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {opportunityItems.map((opportunityItem, index) => {
                const { id } = opportunityItem;
                const isCheckboxChecked =
                  selectedOpportunityItems.findIndex(
                    (selectedOpportunityItem) => selectedOpportunityItem.id === id,
                  ) !== -1;
                return (
                  <TableRow key={opportunityItem.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked,
                        ) => {
                          if (checked) {
                            setSelectedOpportunityItems([
                              ...selectedOpportunityItems,
                              opportunityItem,
                            ]);
                          } else {
                            const newSelectedOpportunityItems = [
                              ...selectedOpportunityItems,
                            ];
                            const selectedIndex = selectedOpportunityItems.findIndex(
                              (selectedOpportunityItem) =>
                                selectedOpportunityItem.id! === id,
                            );
                            newSelectedOpportunityItems.splice(selectedIndex, 1);
                            setSelectedOpportunityItems(newSelectedOpportunityItems);
                          }
                        }}
                        checked={isCheckboxChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>
                    <TableCell>
                      {opportunityItem.opportunity!!.assignmentNumber}
                    </TableCell>
                    <TableCell>{opportunityItem.block?.program?.name}</TableCell>
                    <TableCell>{opportunityItem.block?.brand?.name}</TableCell>
                    <TableCell>{opportunityItem.block?.blockNo}</TableCell>
                    <TableCell>{opportunityItem.block?.item}</TableCell>
                    {/* <TableCell>
                      {opportunityItem.sampleDevUser
                        ? opportunityItem.sampleDevUser!.fullName
                        : ''}
                    </TableCell>
                    <TableCell>
                      {opportunityItem.npdUser ? opportunityItem.npdUser!.fullName : ''}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[...TablePageSize]}
          component="div"
          count={totalItems}
          rowsPerPage={paging.pageSize}
          page={paging.pageNumber}
          onPageChange={handleChangePageNumber}
          onRowsPerPageChange={handleChangePageSize}
        />
      </DialogContent>
      <DialogActions style={{ marginRight: '5px', marginBottom: '10px' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<DoneIcon />}
          onClick={() => {
            const taskItems = selectedOpportunityItems.map((selectedOpportunityItem) => ({
              smTaskId: taskId,
              opportunityItemId: selectedOpportunityItem.id,
              opportunityItem: selectedOpportunityItem,
            }));
            dispatch(selectedTaskItems(taskItems));
            handleClose();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskItemChooseDialog;
