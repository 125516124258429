import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    padding: '0.05px',
    position: 'relative',
    overflow: 'visible',
  },
  button: {
    width: '100%',
    marginTop: '10px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: 0,
    },
  },
  buttonGroup: {
    width: '100%',
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'absolute',
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  activity: {
    padding: theme.spacing(2),
  },
  badge: {
    padding: theme.spacing(0, 2),
  },
  completedDateInput: {
    marginLeft: theme.spacing(1),
  },
  assignee: {
    width: '100%',
  },
  status: {
    width: '100%',
  },
  tab: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#3f51b5',
      height: 4,
    },
    '& .MuiTab-root.Mui-selected': {
      background: '#d9d9d9',
    },
  },
  title: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
