import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Select from 'react-select';

interface AutoCompleteProps {
  name: string;
  placeholder: string;
  options: any;
  handleChange: (value: any) => void;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => any;
  value: any;
  isDisabled?: boolean;
  className?: string;
}

const useStyles = makeStyles(() => ({
  selectWidth: {
    width: '100%',
  },
}));

const AutoComplete: React.FC<AutoCompleteProps> = (props) => {
  const {
    options,
    handleChange,
    placeholder,
    getOptionLabel,
    getOptionValue,
    value,
    name,
    isDisabled = false,
    ...other
  } = props;
  const classes = useStyles();
  return (
    <Select
      className={classes.selectWidth}
      isClearable={true}
      isSearchable={true}
      name={name}
      options={options}
      onChange={handleChange}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      isDisabled={isDisabled}
      menuPlacement="auto"
      {...other}
    />
  );
};

export default AutoComplete;
