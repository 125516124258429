import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Drawer, Loading, Notification, ProgressBar } from './views/components';
import Header from './views/components/Header';
import AppRoute from './views/pages/AppRoute';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './state/configureStore';
import { AppBootstrapState } from './state/app';
import { bootstrap } from './state/app/app.action';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  accountMenu: {
    boxShadow: 'none',
    margin: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginBottom: theme.spacing(4),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
}));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading } = useSelector<AppState, AppBootstrapState>((s) => s.appBootstrap);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(bootstrap());
  }, [dispatch]);

  return (
    <Loading isLoading={loading}>
      <CssBaseline />
      <ProgressBar />
      <div className={classes.root}>
        <Header
          open={open}
          drawerWidth={drawerWidth}
          handleDrawerOpen={handleDrawerOpen}
        />
        <Drawer
          drawerWidth={drawerWidth}
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
      </div>
      <Container maxWidth={false}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <AppRoute />
        </main>
      </Container>
      <Notification />
    </Loading>
  );
};

export default App;
