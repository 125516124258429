import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SampleDevelopmentStatusLabel } from '../../../../constants/sampleDevelopmentStatus';
import { ISampleDevelopmentHl } from '../../../../models/sampleDevelopmentHl.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeSampleDevelopmentHlApprovalRequestDialog,
  openSampleDevelopmentHlApprovalRequestDialog,
  SampleDevelopmentHlApprovalRequest,
  SampleDevelopmentHlApprovalRequestState,
} from '../../../../state/sampleDevelopmentHlApprovalRequest';
import { DateTimeDisplay, TableLoading } from '../../../components';
import SampleDevelopmentHlApprovalRequestDialog from '../../sampleDevelopmentHlApprovalRequest/SampleDevelopmentHlApprovalRequestDialog';
import { fetchPendingApprovalSampleDevHlList } from './pendingApprovalSampleDevHlRequestList.actions';
import { PendingApprovalSampleDevHlRequestListState } from './pendingApprovalSampleDevHlRequestList.reducer';
import { useLocation } from 'react-router-dom';
import history from '../../../../history';
import { TablePageSize } from '../../../../constants';

const PendingApprovalSampleDevHlRequestList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const sampleDevNumber = queryString.get('sampleDevNumber');
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalSampleDevHlRequestList,
  } = useSelector<AppState, PendingApprovalSampleDevHlRequestListState>(
    (state) => state.pendingApprovalSampleDevHlRequestList,
  );

  const { openDialog } = useSelector<AppState, SampleDevelopmentHlApprovalRequestState>(
    (state) => state.sampleDevelopmentHlApprovalRequest,
  );

  const [sampleDevHlAppRequestDlg, setsampleDevHlAppRequestDlg] = useState({
    sampleDevelopmentHl: {} as ISampleDevelopmentHl,
    sampleDevelopmentHlApprovalRequest: {} as SampleDevelopmentHlApprovalRequest,
  });

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalSampleDevHlList(paging));
    }
  }, [dispatch, paging, openDialog]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleCloseSampleDevHlAppRequestDlg = () => {
    dispatch(closeSampleDevelopmentHlApprovalRequestDialog());
    history.replace(`${history.location.pathname}`);
  };

  const editSampleDevHl = useCallback(
    (sampleDevHlAppRequest: SampleDevelopmentHlApprovalRequest): void => {
      setsampleDevHlAppRequestDlg({
        sampleDevelopmentHl: sampleDevHlAppRequest.sampleDevelopmentHl!,
        sampleDevelopmentHlApprovalRequest: sampleDevHlAppRequest,
      });
      dispatch(openSampleDevelopmentHlApprovalRequestDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    const sampleDevHlPrefix = 'SPHL';
    if (sampleDevNumber && sampleDevNumber.startsWith(sampleDevHlPrefix)) {
      const selectedPendingApprovalSampleDevHlRequest =
        pendingApprovalSampleDevHlRequestList.filter(
          (pendingApprovalSampleDevHlRequest) =>
            pendingApprovalSampleDevHlRequest.sampleDevelopmentHl!.sampleDevNumber ===
            sampleDevNumber,
        );
      if (selectedPendingApprovalSampleDevHlRequest.length > 0) {
        editSampleDevHl(selectedPendingApprovalSampleDevHlRequest[0]);
      }
    }
  }, [sampleDevNumber, editSampleDevHl, pendingApprovalSampleDevHlRequestList]);

  return (
    <>
      <Typography variant="h6">Pending Approval Sample Development HL</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Sample Dev. Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {pendingApprovalSampleDevHlRequestList.map(
                (pendingApprovalSampleDevHl, index) => {
                  return (
                    <TableRow key={pendingApprovalSampleDevHl.id}>
                      <TableCell>
                        {index + paging.pageSize * paging.pageNumber + 1}
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevHl!.sampleDevelopmentHl!.sampleDevNumber}
                      </TableCell>
                      <TableCell>
                        {
                          pendingApprovalSampleDevHl!.sampleDevelopmentHl!.block!.program!
                            .name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          pendingApprovalSampleDevHl!.sampleDevelopmentHl!.block!.brand!
                            .name
                        }
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevHl!.sampleDevelopmentHl!.block!.item}
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevHl!.sampleDevelopmentHl!.block!.blockNo}
                      </TableCell>
                      <TableCell>
                        {
                          SampleDevelopmentStatusLabel[
                            pendingApprovalSampleDevHl!.sampleDevelopmentHl!.status!
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        <DateTimeDisplay
                          value={
                            pendingApprovalSampleDevHl!.sampleDevelopmentHl!.createdAt
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevHl!.sampleDevelopmentHl!.createdBy}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => editSampleDevHl(pendingApprovalSampleDevHl)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableLoading>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />

      <SampleDevelopmentHlApprovalRequestDialog
        handleClose={handleCloseSampleDevHlAppRequestDlg}
        sampleDevelopmentHl={sampleDevHlAppRequestDlg.sampleDevelopmentHl}
        sampleDevelopmentHlApprovalRequest={
          sampleDevHlAppRequestDlg.sampleDevelopmentHlApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalSampleDevHlRequestList;
