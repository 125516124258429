import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

export interface BaseSearchFieldProps {
  label: string;
  onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  filters: any;
  filterKey: string;
  fullWidth?: boolean;
}

export type SearchFieldProps = BaseSearchFieldProps | TextFieldProps;

const SearchField: React.FC<BaseSearchFieldProps> = (props) => {
  const { onChange, label, filterKey, filters, fullWidth } = props;
  const classes = useStyles();
  const value = filters[filterKey];

  return (
    <TextField
      name={filterKey}
      type="search"
      label={label}
      className={classes.searchField}
      margin="dense"
      value={value ? value : ''}
      onChange={onChange}
      variant="outlined"
      fullWidth={fullWidth}
    />
  );
};

export default SearchField;
