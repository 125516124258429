import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflow: 'visible',
    padding: '0.05px',
    position: 'relative',
  },
  tabBadge: {
    padding: theme.spacing(0, 2),
  },
  button: {
    marginRight: '50px',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSave: {
    // position: 'relative',
    // backgroundColor: '#7cb342',
    // '&:hover': {
    //   backgroundColor: '#303f9f',
    // },
    // color: 'white',
  },
  buttonGroup: {
    width: '100%',
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'absolute',
    },
  },
  customInput: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '8px', // ปรับ padding-left เป็น 8px
    },
    '& .MuiOutlinedInput-root': {
      transition: 'box-shadow 0.3s ease-in-out',
      '& fieldset': {
        borderWidth: '1px', // กำหนดขนาดเส้นขอบเป็น 1px
      },
      '&:hover fieldset': {
        borderWidth: '1px', // ให้ขนาดขอบคงที่ตอน hover
        borderColor: '#1565c0', // เปลี่ยนสีขอบให้เข้ากับเงา
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px', // ให้ขนาดขอบคงที่ตอน focus
        borderColor: '#1565c0', // เปลี่ยนสีขอบให้เข้ากับเงา
      },
      '&.Mui-focused': {
        boxShadow: '0 0 8px rgba(33, 150, 243, 0.6)', // สีฟุ้งตอน focus
        backgroundColor: '#fafafa',
      },
    },
    marginTop: '4px',
    marginBottom: '8px',
  },
}));

export default useStyles;
