export type ProgramAndBrandRequestStatusType =
  | 'PENDING'
  | 'WAITING_FOR_APPROVE'
  | 'APPROVE'
  | 'REJECT';

const ProgramAndBrandRequestStatus = Object.freeze({
  PENDING: 'PENDING' as ProgramAndBrandRequestStatusType,
  WAITING_FOR_APPROVE: 'WAITING_FOR_APPROVE' as ProgramAndBrandRequestStatusType,
  APPROVE: 'APPROVE' as ProgramAndBrandRequestStatusType,
  REJECT: 'REJECT' as ProgramAndBrandRequestStatusType,
});

export default ProgramAndBrandRequestStatus;

export const ProgramAndBrandRequestStatusLabel = Object.freeze({
  [ProgramAndBrandRequestStatus.PENDING]: 'Pending',
  [ProgramAndBrandRequestStatus.WAITING_FOR_APPROVE]: 'Waiting for Approval',
  [ProgramAndBrandRequestStatus.APPROVE]: 'Approved',
  [ProgramAndBrandRequestStatus.REJECT]: 'Rejected',
});

export const ProgramAndBrandRequestStatusColor = Object.freeze({
  [ProgramAndBrandRequestStatus.PENDING]: 'default',
  [ProgramAndBrandRequestStatus.WAITING_FOR_APPROVE]: 'default',
  [ProgramAndBrandRequestStatus.APPROVE]: 'primary',
  [ProgramAndBrandRequestStatus.REJECT]: 'secondary',
});
