import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflow: 'visible',
    // padding: '0.05px',
    position: 'relative',
    padding: '20px',
    borderRadius: '8px',
  },
  title: {
    margin: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '5px',
  },
  buttonGroup: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  comboBox: {
    minWidth: '280px',
  },
  projectComboBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  projectComboBox: {
    flexBasis: '80%',
  },
  tableContainer: {
    '& .MuiTableCell-sizeSmall': { padding: '4px' },
  },
}));

export default useStyles;
