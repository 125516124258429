import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ProgramAndBrandRequestStatusLabel, TablePageSize } from '../../../constants';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearProgramAndBrandRequest,
  fetchProgramAndBrandRequestList,
  ProgramAndBrandRequest,
  ProgramAndBrandRequestListFilter,
  ProgramAndBrandRequestState,
} from '../../../state/programAndBrandRequest';
import {
  PageButtonGroup,
  PageContainer,
  PageTitle,
  SortLabel,
  TableLoading,
} from '../../components';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import { useFilter, useHasRole, useTableSorting } from '../../hooks';
import roleRoute from '../roleRoute.config';
import ProgramAndBrandRequestFilter from './ProgramAndBrandRequestFilter';
import useStyles from './programAndBrandRequestDetail.style';
import { ProgramAndBrandRequestStatusColor } from '../../../constants/programAndBrandRequestStatus';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchFieldWithIcon from '../../components/SearchFieldWithIcon';

const initialProgramAndBrandRequestFilter = {
  'Status.equalsTo': '',
};

const ProgramAndBrandRequestList: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history } = props;
  const [filters, setFilters] = useFilter<ProgramAndBrandRequestListFilter>(
    initialProgramAndBrandRequestFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: TablePageSize[0],
  });

  const isDisabled = !useHasRole(roleRoute.createProgramBrandRequest);

  const {
    items: programAndBrandRequests,
    totalItems,
    loading,
  } = useSelector<AppState, ProgramAndBrandRequestState>(
    (state) => state.programAndBrandRequest,
  );

  const [sort, setSort] = useTableSorting({
    orderBy: 'requestNumber',
    direction: 'desc',
  });

  const handleChangeFilter = (newFilter: ProgramAndBrandRequestListFilter): void => {
    console.log('====> newFilter ===>', newFilter);
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchProgramAndBrandRequestList(filters, { ...paging, sort }));
    return function cleanUp() {
      dispatch(clearProgramAndBrandRequest());
    };
  }, [dispatch, paging, filters, sort]);

  const addNewProgramAndBrandRequest = (): void => {
    history.push('/programAndBrandRequests/form');
  };

  const editProgramAndBrandRequest = (id: ProgramAndBrandRequest['id']): void => {
    history.push(`/programAndBrandRequests/form/${id}`);
  };

  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <PageTitle>Program Brand Request</PageTitle>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addNewProgramAndBrandRequest}
              startIcon={<AddIcon />}
              disabled={isDisabled}
              style={{ textTransform: 'none' }}
            >
              New Program Brand Request
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProgramAndBrandRequestFilter
            filters={filters}
            onChangeFilter={handleChangeFilter}
            initialFilter={initialProgramAndBrandRequestFilter}
          />
        </Grid>
      </Grid>

      {/* <ProgramAndBrandRequestFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialProgramAndBrandRequestFilter}
      /> */}

      {/* <PageButtonGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={addNewProgramAndBrandRequest}
          startIcon={<AddIcon />}
          disabled={isDisabled}
          style={{ textTransform: 'none' }}
        >
          New Program Brand Request
        </Button>
      </PageButtonGroup> */}

      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field={'requestNumber'}>
                  Request Number
                </SortLabel>
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Requested By</TableCell>
              <TableCell>
                <SortLabel sort={sort} handleSortChange={setSort} field={'createdAt'}>
                  Created At
                </SortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={7}>
              {programAndBrandRequests.map((item, index) => {
                const statusColor =
                  item.status === 'APPROVE'
                    ? 'primary'
                    : item.status === 'REJECT'
                    ? 'secondary'
                    : 'default';
                return (
                  <TableRow key={item.id} style={{ height: '35px' }}>
                    <TableCell>
                      {index + paging.pageSize * paging.pageNumber + 1}
                    </TableCell>

                    <TableCell>
                      <Link
                        component="button"
                        color="primary"
                        onClick={() => {
                          editProgramAndBrandRequest(item.id);
                        }}
                        underline="none"
                      >
                        {item.requestNumber}
                      </Link>
                    </TableCell>
                    {/* <TableCell>{programAndBrandRequest.requestNumber}</TableCell> */}
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {item.requestedByUser
                        ? item.requestedByUser.fullNameWithUserName
                        : ''}
                    </TableCell>
                    <TableCell>
                      <DateTimeDisplay value={item.createdAt} />
                    </TableCell>
                    <TableCell>
                      <Chip
                        variant="outlined"
                        size="small"
                        color={statusColor}
                        label={ProgramAndBrandRequestStatusLabel[item.status!]}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => editProgramAndBrandRequest(item.id)}
                        disabled={isDisabled}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableLoading>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
    </PageContainer>
  );
};

export default ProgramAndBrandRequestList;
