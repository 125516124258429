import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const GoBackButton: React.FC = () => {
  const history = useHistory();

  return (
    <IconButton
      size="medium"
      onClick={() => {
        history.goBack();
      }}
    >
      <ArrowBack color="primary" fontSize="large" />
    </IconButton>
  );
};

export default GoBackButton;
