/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../../state/configureStore';

import {
  BrandComboBox,
  Loading,
  PageContainer,
  PageTitle,
  ProgramComboBox,
} from '../../components';
import useStyles from './programAndBrandRequestDetail.style';
import { pushNotificationMessage } from '../../components/Notification';
import {
  ProgramAndBrandRequest,
  ProgramAndBrandRequestState,
  ProjectRequestItem,
} from '../../../state/programAndBrandRequest';

import {
  clearProgramAndBrandRequestItem,
  createProgramAndBrandRequestItem,
  deleteProgramAndBrandRequestItem,
  ProgramAndBrandRequestItem,
  updateProgramAndBrandRequestItem,
} from '../../../state/programAndBrandRequestItem';
import { ProjectComboBoxWithoutSearchDialog } from '../../components/ProjectComboBox';
import { IProject } from '../../../models/project.model';
import { Program } from '../../../state/program';
import { Brand } from '../../../state/brand';
import { AsyncAutoCompleteOption } from '../../components/AsyncAutoComplete';
import { flattenDeep } from 'lodash';
import ProjectDetailDialog from '../../components/ProjectComboBox/ProjectDetailDialog';
import { AuthState } from '../../../state/auth';
import ProgramAndBrandRequestStatus from '../../../constants/programAndBrandRequestStatus';

interface ProgramAndBrandRequestDetailProps {
  programAndBrandRequestId: number;
}

const ProgramAndBrandRequestDetailSchema = Yup.object().shape({
  programAndBrandRequestId: Yup.number().required('ProgramAndBrandRequest is required'),
  projectId: Yup.number().nullable(),
  projectName: Yup.string().required('Project name is required'),
  projectIsHighPriority: Yup.boolean(),
  programId: Yup.number().nullable(),
  programName: Yup.string().required('Program name is required'),
  // typeOfBusiness: Yup.string().required('Category is required'),
  programIsHighPriority: Yup.boolean(),
  brandId: Yup.number().nullable(),
  brandName: Yup.string().required('Brand name is required'),
  projectRequestItemId: Yup.number(),
  programRequestItemId: Yup.number(),
  brandRequestItemId: Yup.number(),
});

interface ProgramAndBrandRequestDetailRow extends ProgramAndBrandRequestItem {
  smProject?: IProject | null;
  program?: Program | null;
  brand?: Brand | null;
}

const ProgramAndBrandRequestDetail: React.FC<ProgramAndBrandRequestDetailProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const [isHigh, setIsHigh] = useState(false);
  const [openProjectDetailDialog, setOpenProjectDetailDialog] = useState(false);
  const [selectedProjectDetail, setSelectedProjectDetail] = useState<IProject>({});
  const { programAndBrandRequestId } = props;
  const classes = useStyles();
  const { item: programAndBrandRequests, loading } = useSelector<
    AppState,
    ProgramAndBrandRequestState
  >((state) => state.programAndBrandRequest);

  const [localProgramAndBrandRequestItem, setLocalProgramAndBrandRequestItem] = useState<
    ProgramAndBrandRequestDetailRow[]
  >([]);

  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const isAdminOrManager =
    user!.roles!.find((u) => u === 'ADMIN' || u === 'MANAGER') !== undefined;
  const isApproved =
    programAndBrandRequests.status === ProgramAndBrandRequestStatus.APPROVE;

  const addNewProgramAndBrandRequestDetail = () => {
    const newItem = { programAndBrandRequestId };
    setLocalProgramAndBrandRequestItem([...localProgramAndBrandRequestItem, newItem]);
  };

  const remove = (rowNumber: number) => {
    const deletedRow = localProgramAndBrandRequestItem[rowNumber];
    if (
      deletedRow.projectRequestItemId ||
      deletedRow.programRequestItemId ||
      deletedRow.brandRequestItemId
    ) {
      dispatch(deleteProgramAndBrandRequestItem(deletedRow));
    } else {
      const newLocalProgramAndBrandRequestItem = [...localProgramAndBrandRequestItem];
      newLocalProgramAndBrandRequestItem.splice(rowNumber, 1);
      setLocalProgramAndBrandRequestItem(newLocalProgramAndBrandRequestItem);
    }
  };

  const handleSubmit = (values: ProgramAndBrandRequestDetailRow) => {
    ProgramAndBrandRequestDetailSchema.validate(values, { abortEarly: false })
      .then((validatedValues) => {
        if (
          validatedValues.projectRequestItemId ||
          validatedValues.programRequestItemId ||
          validatedValues.brandRequestItemId
        ) {
          // console.log('====> Update =>>', validatedValues);

          dispatch(updateProgramAndBrandRequestItem(validatedValues));
        } else {
          dispatch(createProgramAndBrandRequestItem(validatedValues));
        }
      })
      .catch((err: Yup.ValidationError) => {
        if (err.errors) {
          err.errors.forEach((error) => {
            dispatch(pushNotificationMessage({ message: error, type: 'error' }));
          });
        }
      });
  };

  useEffect(() => {
    const localProgramAndBrandRequestItems = programAndBrandRequests.projectRequestItems
      ? flattenDeep(
          programAndBrandRequests?.projectRequestItems!.map((project) => {
            return project!.programRequestItems!.map((program) => {
              return program!.brandRequestItems!.map((brand) => {
                return {
                  programAndBrandRequestId: programAndBrandRequests.id!,
                  projectId: project.smProject?.id!,
                  projectName: project.smProject?.name! || project.smProjectName,
                  projectIsHighPriority:
                    project.smProject?.isHighPriority! || project.isHighPriority,
                  programId: program.program?.id!,
                  programName: program.program?.name! || program.name,
                  typeOfBusiness:
                    program.program?.typeOfBusiness || program.typeOfBusiness,
                  programIsHighPriority:
                    program.program?.isHighPriority! || program.isHighPriority,
                  brandId: brand.brand?.id!,
                  brandName: brand.brand?.name! || brand.name,
                  smProject: project.smProject!,
                  program: program.program!,
                  brand: brand.brand!,
                  projectRequestItemId: project.id!,
                  programRequestItemId: program.id!,
                  brandRequestItemId: brand.id!,
                };
              });
            });
          }),
        )
      : [];

    setLocalProgramAndBrandRequestItem(localProgramAndBrandRequestItems);
  }, [programAndBrandRequests]);

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={9}>
            <PageTitle>Project Program Brand Request</PageTitle>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => addNewProgramAndBrandRequestDetail()}
                className={classes.button}
                disabled={isApproved}
                style={{ textTransform: 'none', marginTop: 8 }}
              >
                <AddIcon className={classes.leftIcon} />
                Add Project/Program/Brand
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>

        <Loading isLoading={loading}>
          {/* <CardContent> */}
          <TableContainer className={classes.tableContainer}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Project</TableCell>
                  {isAdminOrManager && <TableCell>Project Priority</TableCell>}
                  <TableCell>Program</TableCell>
                  {/* <TableCell>Business Category</TableCell> */}
                  {isAdminOrManager && <TableCell>Program Priority</TableCell>}
                  <TableCell>Brand</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localProgramAndBrandRequestItem.map(
                  (programAndBrandRequestItem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell className={classes.comboBox}>
                          <div className={classes.projectComboBoxContainer}>
                            <div className={classes.projectComboBox}>
                              <ProjectComboBoxWithoutSearchDialog
                                creatable
                                handleChange={(
                                  value: IProject & AsyncAutoCompleteOption,
                                ) => {
                                  const newList = [...localProgramAndBrandRequestItem];
                                  if (value) {
                                    const projectName = value.name
                                      ? value.name
                                      : value.value;
                                    const projectId = value.id ? value.id : null;
                                    newList[index] = {
                                      ...newList[index],
                                      projectName,
                                      projectId,
                                      smProject: value,
                                      projectIsHighPriority: value.isHighPriority
                                        ? value.isHighPriority!
                                        : false,
                                    };
                                  } else {
                                    newList[index] = {
                                      ...newList[index],
                                      projectName: null,
                                      projectId: null,
                                      smProject: null,
                                    };
                                  }

                                  setLocalProgramAndBrandRequestItem(newList);
                                }}
                                selectedValue={
                                  programAndBrandRequestItem.smProject || {
                                    label: programAndBrandRequestItem.projectName!,
                                    value: null,
                                  }
                                }
                                approved
                                isDisabled={isApproved}
                              />
                            </div>
                            <Tooltip title="Project Detail">
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setOpenProjectDetailDialog(true);
                                    setSelectedProjectDetail(
                                      programAndBrandRequestItem.smProject!,
                                    );
                                  }}
                                  disabled={!programAndBrandRequestItem.smProject?.id}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </TableCell>

                        {isAdminOrManager && (
                          <TableCell>
                            <Checkbox
                              color="secondary"
                              checked={programAndBrandRequestItem.projectIsHighPriority}
                              disabled={isApproved}
                              onChange={(event) => {
                                const checkboxValue = event.target.checked;
                                const newList = [...localProgramAndBrandRequestItem];
                                newList[index] = {
                                  ...newList[index],
                                  projectIsHighPriority: checkboxValue,
                                };
                                setLocalProgramAndBrandRequestItem(newList);
                              }}
                            />
                          </TableCell>
                        )}

                        <TableCell className={classes.comboBox}>
                          <ProgramComboBox
                            creatable
                            handleChange={(value: Program & AsyncAutoCompleteOption) => {
                              const newList = [...localProgramAndBrandRequestItem];

                              if (value) {
                                const programName = value.name ? value.name : value.value;
                                const programId = value.id ? value.id : null;
                                newList[index] = {
                                  ...newList[index],
                                  programName,
                                  typeOfBusiness: value.typeOfBusiness ?? '',
                                  programId,
                                  program: value,
                                  programIsHighPriority: value.isHighPriority
                                    ? value.isHighPriority!
                                    : false,
                                };
                              } else {
                                newList[index] = {
                                  ...newList[index],
                                  programName: null,
                                  typeOfBusiness: '',
                                  programId: null,
                                  program: null,
                                };
                              }

                              setLocalProgramAndBrandRequestItem(newList);
                            }}
                            selectedValue={
                              programAndBrandRequestItem.program! || {
                                label: programAndBrandRequestItem.programName!,
                                value: null,
                              }
                            }
                            isDisabled={isApproved}
                          />
                        </TableCell>

                        {/* <TableCell style={{ minWidth: 200 }}>
                          <TextField
                            fullWidth
                            style={{ marginTop: '4px' }}
                            value={programAndBrandRequestItem.typeOfBusiness}
                            onChange={(event) => {
                              const progCateValue = event.target.value
                                ? event.target.value.toUpperCase()
                                : '';
                              const newList = [...localProgramAndBrandRequestItem];
                              newList[index].typeOfBusiness = progCateValue;
                              //newList[index].program!.typeOfBusiness = progCateValue;

                              setLocalProgramAndBrandRequestItem(newList);
                            }}
                          />
                        </TableCell> */}

                        {isAdminOrManager && (
                          <TableCell>
                            <Checkbox
                              color="secondary"
                              checked={programAndBrandRequestItem.programIsHighPriority}
                              disabled={isApproved}
                              onChange={(event) => {
                                const checkboxValue = event.target.checked;
                                const newList = [...localProgramAndBrandRequestItem];
                                newList[index] = {
                                  ...newList[index],
                                  programIsHighPriority: checkboxValue,
                                };
                                setLocalProgramAndBrandRequestItem(newList);
                              }}
                            />
                          </TableCell>
                        )}

                        <TableCell className={classes.comboBox}>
                          <BrandComboBox
                            creatable
                            handleChange={(value: Brand & AsyncAutoCompleteOption) => {
                              const newList = [...localProgramAndBrandRequestItem];

                              if (value) {
                                const brandName = value.name ? value.name : value.value;
                                const brandId = value.id ? value.id : null;
                                newList[index] = {
                                  ...newList[index],
                                  brandName,
                                  brandId,
                                  brand: value,
                                };
                              } else {
                                newList[index] = {
                                  ...newList[index],
                                  brandName: null,
                                  brandId: null,
                                  brand: null,
                                };
                              }

                              setLocalProgramAndBrandRequestItem(newList);
                            }}
                            selectedValue={
                              programAndBrandRequestItem.brand! || {
                                label: programAndBrandRequestItem.brandName!,
                                value: null,
                              }
                            }
                            isDisabled={isApproved}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" width="100%" justifyContent={'right'}>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                console.log('==> Save ==>', programAndBrandRequestItem);
                                handleSubmit(programAndBrandRequestItem);
                              }}
                              className={classes.button}
                              disabled={isApproved}
                              style={{ textTransform: 'none' }}
                            >
                              <SaveIcon className={classes.leftIcon} />
                              Save
                            </Button>

                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => remove(index)}
                              disabled={isApproved}
                              style={{ textTransform: 'none' }}
                            >
                              <DeleteIcon className={classes.leftIcon} />
                              Delete
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* </CardContent> */}
        </Loading>
      </Card>
      <ProjectDetailDialog
        open={openProjectDetailDialog}
        handleClose={() => {
          setOpenProjectDetailDialog(false);
        }}
        smProjectId={selectedProjectDetail.id!}
      />
    </>
  );
};

export default ProgramAndBrandRequestDetail;
