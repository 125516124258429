/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  searchField: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  customInput: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '8px', // ปรับ padding-left เป็น 8px
    },
    '& .MuiOutlinedInput-root': {
      transition: 'box-shadow 0.3s ease-in-out',
      '& fieldset': {
        borderWidth: '1px', // กำหนดขนาดเส้นขอบเป็น 1px
      },
      '&:hover fieldset': {
        borderWidth: '1px', // ให้ขนาดขอบคงที่ตอน hover
        borderColor: '#1565c0', // เปลี่ยนสีขอบให้เข้ากับเงา
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px', // ให้ขนาดขอบคงที่ตอน focus
        borderColor: '#1565c0', // เปลี่ยนสีขอบให้เข้ากับเงา
      },
      '&.Mui-focused': {
        boxShadow: '0 0 8px rgba(33, 150, 243, 0.6)', // สีฟุ้งตอน focus
        backgroundColor: '#fafafa', // พื้นหลังคล้ายๆ สีตอน hover
      },
    },
    marginTop: '4px',
    marginBottom: '8px',
  },
}));

export interface BaseSearchFieldProps {
  label: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  filters: any;
  filterKey: string;
  fullWidth?: boolean;
}

const SearchFieldWithIcon: React.FC<BaseSearchFieldProps> = (props) => {
  const { onChange, label, filterKey, filters, fullWidth, placeholder } = props;
  const classes = useStyles();
  const value = filters[filterKey];

  return (
    <>
      <Typography variant="body2">{label ?? placeholder}</Typography>
      <TextField
        name={filterKey}
        placeholder={placeholder}
        className={classes.customInput}
        size="small"
        // margin="dense"
        value={value ? value : ''}
        onChange={onChange}
        variant="outlined"
        fullWidth={fullWidth}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: 'lightgray', marginLeft: '2px' }} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default SearchFieldWithIcon;
