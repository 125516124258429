import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
  Link,
  Chip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import {
//   exportOpportunityItemsAsExcel,
//   exportOpportunityItemsAsExcelWithSampleDevelopmentRequestForm,
// } from '../../../services/opportunityItemService';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearOpportunityItem,
  fetchOpportunityItemList,
  OpportunityItemListFilter,
  OpportunityItemState,
} from '../../../state/opportunityItem';
import {
  OpportunityLinkButton,
  PageContainer,
  PageTitle,
  TableLoading,
} from '../../components';
import { useFilter } from '../../hooks';
import OpportunityItemFilter from './OpportunityItemFilter';
import ProjectDetailDialog from '../../components/ProjectComboBox/ProjectDetailDialog';
import { IProject } from '../../../models/project.model';
import ChipListUser from '../../components/ChipListUser';
import { TablePageSize } from '../../../constants';

const initialOpportunityItemFilter: OpportunityItemListFilter = {};

const OpportunityItemList: React.FC = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilter<OpportunityItemListFilter>(
    initialOpportunityItemFilter,
  );
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const {
    items: opportunityItems,
    totalItems,
    loading,
  } = useSelector<AppState, OpportunityItemState>((state) => state.opportunityItem);

  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  useEffect(() => {
    dispatch(fetchOpportunityItemList(filters, paging));
    return function cleanUp() {
      dispatch(clearOpportunityItem());
    };
  }, [dispatch, paging, filters]);

  const handleChangeFilter = (newFilter: OpportunityItemListFilter): void => {
    setFilters(newFilter);
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  // const exportExcel = () => {
  //   exportOpportunityItemsAsExcel(filters).catch((err) => console.error(err));
  // };

  // const exportExcelWithSampleDevelopmentRequestForm = () => {
  //   exportOpportunityItemsAsExcelWithSampleDevelopmentRequestForm(filters).catch((err) =>
  //     console.error(err),
  //   );
  // };

  // const getActiveSquad = (opportunityItem: IOpportunityItem) => {
  //   return (
  //     opportunityItem.opportunity?.smProject?.smProjectSquads || ([] as IProjectSquad[])
  //   ).find((s) => s.active);
  // };

  return (
    <PageContainer>
      <PageTitle>Assignment Item</PageTitle>
      <OpportunityItemFilter
        filters={filters}
        onChangeFilter={handleChangeFilter}
        initialFilter={initialOpportunityItemFilter}
      />
      {/* {!isSmallScreen && (
        <div className={classes.filterContainer}>
          <Button variant={'contained'} className={classes.button} onClick={exportExcel}>
            Export Excel
          </Button>
          <Button
            variant={'contained'}
            className={classes.button}
            onClick={exportExcelWithSampleDevelopmentRequestForm}
          >
            Export Sample Development Request Form
          </Button>
        </div>
      )} */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Assignment Number</TableCell>
            <TableCell>Our Company</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Program</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>CS</TableCell>
            <TableCell>Block No</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Sample Dev/NPD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={11}>
            {opportunityItems.map((opportunityItem, index) => {
              const sMembers =
                opportunityItem.respMembers
                  ?.filter((f) => f.status?.startsWith('Sales'))
                  .map((s) => {
                    return {
                      name: s.dspFullName ?? '',
                      activated: s.active ?? false,
                    };
                  }) ?? [];

              const cMembers =
                opportunityItem.respMembers
                  ?.filter((f) => f.status?.startsWith('CS'))
                  .map((s) => {
                    return {
                      name: s.dspFullName ?? '',
                      activated: s.active ?? false,
                    };
                  }) ?? [];
              // const salesNames =
              //   opportunityItem.opportunity?.responsibility?.responsibilitySalesTeamMembers
              //     ?.filter((rs) => rs.active === true)
              //     ?.map((rs) => {
              //       const status =
              //         rs.status === 'P' ? 'Sales' : `Sales${rs.status?.replace('S', '')}`;
              //       const userFullName = rs.salesTeamMember?.user?.activated
              //         ? `${rs.salesTeamMember?.user?.fullName} (${status})`
              //         : `${rs.salesTeamMember?.user?.fullName} (${status}) (Inactive)`;
              //       return {
              //         name: userFullName,
              //         activated: rs.salesTeamMember?.user?.activated,
              //         userLeaveTotalDay: rs.salesTeamMember?.user?.userLeaveTotalDay,
              //       };
              //     }) || [];
              // const csNames =
              //   opportunityItem.opportunity?.responsibility?.responsibilityWorkingTeamMembers
              //     ?.filter(
              //       (rc) =>
              //         rc.active === true &&
              //         rc.smWorkingTeamMember?.smWorkingTeam?.smWorkingGroup?.name ===
              //           'CS',
              //     )
              //     .sort((a, b) => a.status?.localeCompare(b.status || '') || 0)
              //     ?.map((rs) => {
              //       const status = rs.status === 'P' ? 'CS' : `C${rs.status}`;
              //       const userFullName = rs.smWorkingTeamMember?.user?.activated
              //         ? `${rs.smWorkingTeamMember?.user?.fullName} (${status})`
              //         : `${rs.smWorkingTeamMember?.user?.fullName} (${status}) (Inactive)`;
              //       return {
              //         name: userFullName,
              //         activated: rs.smWorkingTeamMember?.user?.activated,
              //         userLeaveTotalDay: rs.smWorkingTeamMember?.user?.userLeaveTotalDay,
              //       };
              //     }) || [];

              const npdUserFullName = opportunityItem.sampleDevUser
                ? opportunityItem.sampleDevUser.activated
                  ? opportunityItem.sampleDevUser.fullName
                  : `${opportunityItem.sampleDevUser.fullName} (Inactive)`
                : opportunityItem.npdUser?.activated
                ? opportunityItem.npdUser?.fullName
                : `${opportunityItem.npdUser?.fullName} (Inactive)`;

              const npdUserIsActivated = opportunityItem.sampleDevUser
                ? opportunityItem.sampleDevUser.activated
                : opportunityItem.npdUser?.activated;

              const npdUserLeaveDay = opportunityItem.sampleDevUser
                ? opportunityItem.sampleDevUser?.userLeaveTotalDay
                : opportunityItem.npdUser?.userLeaveTotalDay;

              const npdName = {
                name: npdUserFullName,
                activated: npdUserIsActivated,
                userLeaveTotalDay: npdUserLeaveDay,
              };

              return (
                <TableRow key={opportunityItem.id}>
                  <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                  <TableCell>
                    <OpportunityLinkButton opportunity={opportunityItem.opportunity} />
                  </TableCell>
                  <TableCell>{opportunityItem.opportunity?.company?.name}</TableCell>
                  <TableCell>
                    <Link
                      href="#"
                      onClick={() => {
                        setSelectedProject(
                          opportunityItem?.opportunity?.smProject || null,
                        );
                      }}
                    >
                      {opportunityItem.opportunity?.smProject?.name}
                    </Link>
                  </TableCell>
                  <TableCell>{opportunityItem.block?.program?.name}</TableCell>
                  <TableCell>{opportunityItem.block?.brand?.name}</TableCell>
                  <TableCell>
                    <ChipListUser list={sMembers} noAllLabel />
                  </TableCell>
                  <TableCell>
                    <ChipListUser list={cMembers} noAllLabel />
                  </TableCell>
                  <TableCell>{opportunityItem.block?.blockNo}</TableCell>
                  <TableCell>{opportunityItem.block?.item}</TableCell>
                  <TableCell>
                    <ChipListUser list={[npdName]} noAllLabel />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <ProjectDetailDialog
        smProjectId={selectedProject?.id}
        handleClose={() => {
          setSelectedProject(null);
        }}
        open={selectedProject !== null}
      />
    </PageContainer>
  );
};

export default OpportunityItemList;
