import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import { fetchSalesTeamRoleList, SalesTeamRoleState } from '../../../state/salesTeamRole';
import {
  clearWorkingTeamMember,
  deleteWorkingTeamMember,
  fetchWorkingTeamMemberList,
  WorkingTeamMember,
  WorkingTeamMemberState,
} from '../../../state/workingTeamMember';
import { Loading, PageTitle } from '../../components';
import useStyles from './workingTeamFormDetail.style';
import WorkingTeamFormDetailRow from './WorkingTeamFormDetailRow';

interface WorkingTeamFormDetailProps {
  workingTeamId: number;
}

const WorkingTeamFormDetail: React.FC<WorkingTeamFormDetailProps> = (props) => {
  const { workingTeamId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { items: workingTeamMembers, loading: isLoading } = useSelector<
    AppState,
    WorkingTeamMemberState
  >((state) => state.workingTeamMember);

  const [members, setMembers] = useState<WorkingTeamMember[]>([]);

  const addNewWorkingTeamMember = () => {
    setMembers([
      ...members,
      { id: 0, userId: 0, salesTeamRoleId: 0, smWorkingTeamId: workingTeamId },
    ]);
  };

  const onClickDeleteSalesTeamMember = (index: number) => {
    const member = members[index];
    if (member.id !== 0 && member.id !== undefined) {
      dispatch(deleteWorkingTeamMember(member));
    } else {
      const newMembers = [...members];
      newMembers.splice(index, 1);
      setMembers([...newMembers]);
    }
  };

  const { items: salesTeamRoles } = useSelector<AppState, SalesTeamRoleState>(
    (state) => state.salesTeamRole,
  );

  useEffect(() => {
    dispatch(fetchSalesTeamRoleList());

    if (!!workingTeamId) {
      dispatch(
        fetchWorkingTeamMemberList(
          {
            'SmWorkingTeamId.EqualsTo': workingTeamId,
            'User.Activated.EqualsTo': true,
          },
          { pageNumber: 0, pageSize: 99999 },
        ),
      );
    } else {
      dispatch(clearWorkingTeamMember());
    }
    return function cleanUp() {
      dispatch(clearWorkingTeamMember());
    };
  }, [dispatch]);

  useEffect(() => {
    setMembers([...workingTeamMembers]);
  }, [workingTeamMembers]);

  return (
    <Card className={classes.root}>
      <PageTitle>Working Team Member</PageTitle>
      <Loading isLoading={isLoading}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>CS Code</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member, index) => {
                const roles = salesTeamRoles.filter(
                  (f) =>
                    f.mainRole === 'CS' || f.mainRole === 'SSO' || f.mainRole === 'NPD',
                );

                return (
                  <WorkingTeamFormDetailRow
                    key={index}
                    index={index}
                    workingTeamId={workingTeamId}
                    workingTeamMember={member}
                    salesTeamRoles={roles}
                    handleDelete={() => onClickDeleteSalesTeamMember(index)}
                  />
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addNewWorkingTeamMember();
              }}
              className={classes.button}
            >
              <AddIcon className={classes.leftIcon} />
              Add Working Team Member
            </Button>
          </div>
        </CardContent>
      </Loading>
    </Card>
  );
};

export default WorkingTeamFormDetail;
