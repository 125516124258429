import React from 'react';
import { Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { FilterContainer, SearchDropdownField, SearchField } from '../../components';

import { ProgramAndBrandRequestListFilter } from '../../../state/programAndBrandRequest';
import { IFilterContainerConfigProps } from '../../components/FilterContainer';
import SearchFieldWithIcon from '../../components/SearchFieldWithIcon';
import {
  ProgramAndBrandRequestStatus,
  ProgramAndBrandRequestStatusLabel,
} from '../../../constants';
import SearchDropdownField2 from '../../components/SearchDropdownField2';

const useStyles = makeStyles({
  customSelect: {
    '& .MuiOutlinedInput-root': {
      height: '48px', // ปรับความสูงทั้งหมดของ Select
    },
    '& .MuiSelect-select': {
      padding: '10px 10px', // ปรับ padding ให้พอดีกับความสูง 48px
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1565c0',
      borderWidth: '1px',
      boxShadow: '0 0 8px rgba(21, 101, 192, 0.5)', // เพิ่ม box-shadow ฟุ้งตอน focus
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1565c0', // สีขอบเมื่อ hover
      borderWidth: '1px',
    },
  },
});

const ProgramAndBrandRequestFilter: React.FC<
  IFilterContainerConfigProps<ProgramAndBrandRequestListFilter>
> = (props) => {
  const classes = useStyles();

  return (
    <FilterContainer
      {...props}
      render={(filterProps) => {
        const { localFilter, handleChangeFilter } = filterProps;
        return (
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12} md={3} lg={3}>
              <SearchFieldWithIcon
                fullWidth={true}
                label="Request Number"
                placeholder="Search by Request Number"
                filterKey={'RequestNumber.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              {/* <SearchField
                fullWidth={true}
                label="Description"
                filterKey={'Description.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              /> */}
              <SearchFieldWithIcon
                fullWidth={true}
                label="Description"
                placeholder="Search by Description"
                filterKey={'Description.startsWith'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              {/* <SearchField
                fullWidth={true}
                label="Requested By"
                filterKey={'RequestedByUser.FullNameWithUserName.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              /> */}
              <SearchFieldWithIcon
                fullWidth={true}
                label="Requested By"
                placeholder="Search by Requested By"
                filterKey={'RequestedByUser.FullNameWithUserName.contains'}
                filters={localFilter}
                onChange={handleChangeFilter}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <SearchDropdownField2
                label="Status"
                filterKey={'Status.equalsTo'}
                filters={localFilter}
                onChange={handleChangeFilter}
                id="status"
              >
                <MenuItem value={''}>All</MenuItem>
                {Object.entries(ProgramAndBrandRequestStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {ProgramAndBrandRequestStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </SearchDropdownField2>
            </Grid>
            {/* <Grid item xs={12} md={3} lg={2}>
              <Typography variant="body2">Status</Typography>
              <Select
                variant="outlined"
                fullWidth
                className={classes.customSelect}
                name="Status.equalsTo"
                value={localFilter['Status.equalsTo']}
              >
                <MenuItem key="all" value={''}>
                  All
                </MenuItem>
                {Object.entries(ProgramAndBrandRequestStatus).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {ProgramAndBrandRequestStatusLabel[value]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid> */}
          </Grid>
        );
      }}
    />
  );
};

export default ProgramAndBrandRequestFilter;
