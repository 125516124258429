import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SampleDevelopmentStatusLabel } from '../../../../constants/sampleDevelopmentStatus';
import { ISampleDevelopmentNl } from '../../../../models/sampleDevelopmentNl.model';
import { PageFilter } from '../../../../state/app';
import { AppState } from '../../../../state/configureStore';
import {
  closeSampleDevelopmentNlApprovalRequestDialog,
  openSampleDevelopmentNlApprovalRequestDialog,
  SampleDevelopmentNlApprovalRequest,
  SampleDevelopmentNlApprovalRequestState,
} from '../../../../state/sampleDevelopmentNlApprovalRequest';
import { DateTimeDisplay, TableLoading } from '../../../components';
import SampleDevelopmentNlApprovalRequestDialog from '../../sampleDevelopmentNlApprovalRequest/SampleDevelopmentNlApprovalRequestDialog';
import { fetchPendingApprovalSampleDevNlList } from './pendingApprovalSampleDevNlRequestList.actions';
import { PendingApprovalSampleDevNlRequestListState } from './pendingApprovalSampleDevNlRequestList.reducer';
import { useLocation } from 'react-router-dom';
import history from '../../../../history';
import { TablePageSize } from '../../../../constants';

const PendingApprovalSampleDevNlRequestList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const sampleDevNumber = queryString.get('sampleDevNumber');
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 10,
  });

  const {
    loading,
    totalItems,
    items: pendingApprovalSampleDevNlRequestList,
  } = useSelector<AppState, PendingApprovalSampleDevNlRequestListState>(
    (state) => state.pendingApprovalSampleDevNlRequestList,
  );

  const { openDialog } = useSelector<AppState, SampleDevelopmentNlApprovalRequestState>(
    (state) => state.sampleDevelopmentNlApprovalRequest,
  );

  const [sampleDevNlAppRequestDlg, setsampleDevNlAppRequestDlg] = useState({
    sampleDevelopmentNl: {} as ISampleDevelopmentNl,
    sampleDevelopmentNlApprovalRequest: {} as SampleDevelopmentNlApprovalRequest,
  });

  useEffect(() => {
    if (!openDialog) {
      dispatch(fetchPendingApprovalSampleDevNlList(paging));
    }
  }, [dispatch, paging, openDialog]);

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  const handleCloseSampleDevNlAppRequestDlg = () => {
    dispatch(closeSampleDevelopmentNlApprovalRequestDialog());
    history.replace(`${history.location.pathname}`);
  };

  const editSampleDevNl = useCallback(
    (sampleDevNlAppRequest: SampleDevelopmentNlApprovalRequest): void => {
      setsampleDevNlAppRequestDlg({
        sampleDevelopmentNl: sampleDevNlAppRequest.sampleDevelopmentNl!,
        sampleDevelopmentNlApprovalRequest: sampleDevNlAppRequest,
      });
      dispatch(openSampleDevelopmentNlApprovalRequestDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    const sampleDevNlPrefix = 'SPNL';
    if (sampleDevNumber && sampleDevNumber.startsWith(sampleDevNlPrefix)) {
      const selectedPendingApprovalSampleDevNlRequest =
        pendingApprovalSampleDevNlRequestList.filter(
          (pendingApprovalSampleDevNlRequest) =>
            pendingApprovalSampleDevNlRequest.sampleDevelopmentNl!.sampleDevNumber ===
            sampleDevNumber,
        );
      if (selectedPendingApprovalSampleDevNlRequest.length > 0) {
        editSampleDevNl(selectedPendingApprovalSampleDevNlRequest[0]);
      }
    }
  }, [sampleDevNumber, editSampleDevNl, pendingApprovalSampleDevNlRequestList]);

  return (
    <>
      <Typography variant="h6">Pending Approval Sample Development NL</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Sample Dev. Number</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableLoading isLoading={loading} colSpan={10}>
              {pendingApprovalSampleDevNlRequestList.map(
                (pendingApprovalSampleDevNl, index) => {
                  return (
                    <TableRow key={pendingApprovalSampleDevNl.id}>
                      <TableCell>
                        {index + paging.pageSize * paging.pageNumber + 1}
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevNl!.sampleDevelopmentNl!.sampleDevNumber}
                      </TableCell>
                      <TableCell>
                        {
                          pendingApprovalSampleDevNl!.sampleDevelopmentNl!.block!.program!
                            .name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          pendingApprovalSampleDevNl!.sampleDevelopmentNl!.block!.brand!
                            .name
                        }
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevNl!.sampleDevelopmentNl!.block!.item}
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevNl!.sampleDevelopmentNl!.block!.blockNo}
                      </TableCell>
                      <TableCell>
                        {
                          SampleDevelopmentStatusLabel[
                            pendingApprovalSampleDevNl!.sampleDevelopmentNl!.status!
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        <DateTimeDisplay
                          value={
                            pendingApprovalSampleDevNl!.sampleDevelopmentNl!.createdAt
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {pendingApprovalSampleDevNl!.sampleDevelopmentNl!.createdBy}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => editSampleDevNl(pendingApprovalSampleDevNl)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableLoading>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[...TablePageSize]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      <SampleDevelopmentNlApprovalRequestDialog
        handleClose={handleCloseSampleDevNlAppRequestDlg}
        sampleDevelopmentNl={sampleDevNlAppRequestDlg.sampleDevelopmentNl}
        sampleDevelopmentNlApprovalRequest={
          sampleDevNlAppRequestDlg.sampleDevelopmentNlApprovalRequest
        }
      />
    </>
  );
};

export default PendingApprovalSampleDevNlRequestList;
