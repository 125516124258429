/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import {
  clearProgramAndBrandRequest,
  createProgramAndBrandRequest,
  fetchProgramAndBrandRequest,
  updateProgramAndBrandRequest,
  ProgramAndBrandRequest,
  ProgramAndBrandRequestState,
} from '../../../state/programAndBrandRequest';
import { AppState } from '../../../state/configureStore';
import {
  Loading,
  PageContainer,
  PageTitle,
  PageTopMenuContainer,
} from '../../components';
import ProgramAndBrandRequestDetail from './ProgramAndBrandRequestDetail';
import ProgramAndBrandRequestActionMenu from './ProgramAndBrandRequestActionMenu';
import { ProgramAndBrandRequestStatusLabel } from '../../../constants';
import { AuthState } from '../../../state/auth';
import useStyles from './programAndBrandRequestForm.style';
import { Save as SaveIcon } from '@material-ui/icons';
import GoBackButton from '../../components/GoBackButton';
import ProgramAndBrandRequestStatus from '../../../constants/programAndBrandRequestStatus';

const ProgramAndBrandRequestSchema = Yup.object().shape({
  description: Yup.string().nullable(true).required('Description is required'),
  requestedByUser: Yup.object().nullable(true).required('Requester is required'),
});

interface ProgramAndBrandRequestFormRouteParamsProps {
  id?: string;
}

interface ProgramAndBrandRequestFormProps
  extends RouteComponentProps<ProgramAndBrandRequestFormRouteParamsProps> {}

const ProgramAndBrandRequestForm: React.FC<ProgramAndBrandRequestFormProps> = (props) => {
  const dispatch = useDispatch();
  const { match } = props;
  const classes = useStyles();
  const { user } = useSelector<AppState, AuthState>((state) => state.auth);
  const { item: programAndBrandRequest, loading } = useSelector<
    AppState,
    ProgramAndBrandRequestState
  >((state) => state.programAndBrandRequest);

  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = useMemo(() => paramsId !== undefined, [paramsId]);
  const isApproved =
    programAndBrandRequest.status === ProgramAndBrandRequestStatus.APPROVE;

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchProgramAndBrandRequest(+paramsId!));
    } else {
      dispatch(clearProgramAndBrandRequest());
    }

    return function cleanUp() {
      dispatch(clearProgramAndBrandRequest());
    };
  }, [paramsId, dispatch, isUpdateMode]);

  const handleSubmit = (values: ProgramAndBrandRequest, actions: FormikHelpers<any>) => {
    const newProgramAndBrandRequest = {
      ...values,
      requestedBy: values.requestedByUser!.id,
    };
    if (isUpdateMode) {
      dispatch(updateProgramAndBrandRequest(newProgramAndBrandRequest));
    } else {
      dispatch(createProgramAndBrandRequest(newProgramAndBrandRequest));
    }
  };

  return (
    <>
      <PageContainer>
        <Formik
          enableReinitialize={true}
          initialValues={{ requestedByUser: user, ...programAndBrandRequest }}
          validationSchema={ProgramAndBrandRequestSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {({ values, handleChange, errors, submitForm }) => {
            return (
              <Form>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid item xs={8}>
                    <Box display="flex" width="100%">
                      <GoBackButton />
                      <PageTitle>
                        {isUpdateMode
                          ? `Update Project Program Brand Request`
                          : `New Project Program Brand Request`}
                      </PageTitle>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={1}>
                    <GoBackButton /><GoBackButton />
                  </Grid>
                  <Grid item xs={7}>
                    <PageTitle>
                      {isUpdateMode
                        ? `Update Project Program Brand Request`
                        : `New Project Program Brand Request`}
                    </PageTitle>
                  </Grid> */}
                  <Grid item xs={4}>
                    <PageTopMenuContainer>
                      {programAndBrandRequest.id && (
                        <ProgramAndBrandRequestActionMenu
                          programAndBrandRequest={programAndBrandRequest}
                        />
                      )}
                      <Button
                        type={'submit'}
                        variant="contained"
                        color={!isUpdateMode ? 'primary' : 'default'}
                        startIcon={<SaveIcon />}
                        disabled={isApproved}
                        style={{
                          textTransform: 'none',
                        }}
                      >
                        {isUpdateMode ? `Update` : `Create`}
                      </Button>
                    </PageTopMenuContainer>
                  </Grid>
                </Grid>

                <Loading isLoading={loading}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        {isUpdateMode && (
                          <>
                            <Typography variant="body2">Request Number</Typography>
                            <TextField
                              type="input"
                              fullWidth
                              // margin="dense"
                              variant="outlined"
                              name="requestNumber"
                              value={values.requestNumber || ''}
                              style={{ backgroundColor: '#f5f5f5' }}
                              className={classes.customInput}
                              size="small"
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {isUpdateMode && (
                          <>
                            <Typography variant="body2">Requester</Typography>
                            <TextField
                              fullWidth
                              name="Requester"
                              type="input"
                              variant="outlined"
                              // margin="dense"
                              value={
                                values.requestedByUser
                                  ? values.requestedByUser!.fullNameWithUserName
                                  : ''
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: '#f5f5f5' }}
                              // disabled={isApproved}
                              className={classes.customInput}
                              size="small"
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {isUpdateMode && (
                          <>
                            <Typography variant="body2">Status</Typography>
                            <TextField
                              fullWidth
                              type="input"
                              // margin="dense"
                              variant="outlined"
                              name="status"
                              value={
                                ProgramAndBrandRequestStatusLabel[values.status!] || ''
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: '#f5f5f5' }}
                              className={classes.customInput}
                              size="small"
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body2">Description</Typography>
                        <TextField
                          className={classes.customInput}
                          type="input"
                          // margin="dense"
                          variant="outlined"
                          name="description"
                          placeholder="กรอกข้อมูลคำอธิบายเพิ่มเติม"
                          fullWidth
                          value={values.description || ''}
                          onChange={handleChange}
                          error={!!errors.description}
                          helperText={errors.description}
                          disabled={isApproved}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Loading>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>

      {isUpdateMode && (
        <ProgramAndBrandRequestDetail programAndBrandRequestId={+paramsId!} />
      )}
    </>
  );
};

export default ProgramAndBrandRequestForm;
