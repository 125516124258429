import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesTeamMember,
  deleteSalesTeamMember,
  fetchSalesTeamMemberList,
  SalesTeamMember,
  SalesTeamMemberState,
} from '../../../state/salesTeamMember';
import { Loading, PageTitle } from '../../components';
import useStyles from './salesTeamFormDetail.style';
import SalesTeamFormDetailRow from './SalesTeamFormDetailRow';

interface SalesTeamFormDetailProps {
  salesTeamId: number;
}

const SalesTeamFormDetail: React.FC<SalesTeamFormDetailProps> = (props) => {
  const { salesTeamId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [members, setMembers] = useState<SalesTeamMember[]>([]);
  const { items: salesTeamMembers, loading: isLoading } = useSelector<
    AppState,
    SalesTeamMemberState
  >((state) => state.salesTeamMember);

  const checkNewSalesTeamMemberRowIsExisted = (): boolean => {
    return members.findIndex((member) => member.id === 0) === -1;
  };
  const addNewSalesTeamMember = () => {
    if (checkNewSalesTeamMemberRowIsExisted()) {
      setMembers([...members, { id: 0, userId: 0, salesTeamRoleId: 0 }]);
    }
  };

  const onClickDeleteSalesTeamMember = (index: number) => {
    const member = members[index];
    if (member.id !== 0 && member.id !== undefined) {
      dispatch(deleteSalesTeamMember(member));
    } else {
      const newMembers = [...members];
      newMembers.splice(index, 1);
      setMembers([...newMembers]);
    }
  };

  useEffect(() => {
    if (!!salesTeamId) {
      dispatch(
        fetchSalesTeamMemberList(
          { 'SalesTeamId.EqualsTo': salesTeamId },
          { pageNumber: 0, pageSize: 99999 },
        ),
      );
    } else {
      dispatch(clearSalesTeamMember());
    }
    return function cleanUp() {
      dispatch(clearSalesTeamMember());
    };
  }, [dispatch, salesTeamId]);

  useEffect(() => {
    setMembers([...salesTeamMembers]);
  }, [salesTeamMembers]);

  const [userActive, setUserActive] = useState<boolean>(true);

  return (
    <Card className={classes.root}>
      <PageTitle>Sales</PageTitle>
      <Loading isLoading={isLoading}>
        <CardContent>
          <Table className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members
                .filter((f) => !userActive || (f.user && f.user.activated))
                .map((member, index) => {
                  return (
                    <SalesTeamFormDetailRow
                      key={index}
                      index={index}
                      salesTeamId={salesTeamId}
                      salesTeamMember={member}
                      handleDelete={() => onClickDeleteSalesTeamMember(index)}
                    />
                  );
                })}
            </TableBody>
          </Table>

          <div className={classes.buttonGroup}>
            <FormControlLabel
              control={
                <Switch
                  checked={userActive}
                  onChange={() => {
                    setUserActive(!userActive);
                  }}
                  color="primary"
                />
              }
              label={userActive ? 'User Active' : 'User Inactive'}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addNewSalesTeamMember();
              }}
              className={classes.button}
              disabled={!checkNewSalesTeamMemberRowIsExisted()}
              style={{ textTransform: 'none' }}
            >
              <AddIcon className={classes.leftIcon} />
              Add Sales
            </Button>
          </div>
        </CardContent>
      </Loading>
    </Card>
  );
};

export default SalesTeamFormDetail;
