import {
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  People as PeopleIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PageFilter } from '../../../state/app';
import { AppState } from '../../../state/configureStore';
import {
  clearSalesTeam,
  fetchSalesTeamList,
  SalesTeam,
  SalesTeamListFilter,
  SalesTeamState,
} from '../../../state/salesTeam';
import {
  PageContainer,
  PageTitle,
  SearchDropdownField,
  SearchField,
  TableLoading,
} from '../../components';
import SalesTeamMemberDialog from '../../components/SalesTeamComboBox/SalesTeamMemberDialog';
import { useFilter, useHasRole } from '../../hooks';
import roleRoute from '../roleRoute.config';
import useStyles from './salesTeamList.style';

const SalesTeamList: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const classes = useStyles();
  const isDisabled = !useHasRole(roleRoute.createSalesTeam);
  const [filters, setFilters] = useFilter<SalesTeamListFilter>({
    'Name.startsWith': '',
    'Owner.FullNameWithUserName.Contains': '',
    'Active.EqualsTo': true,
  });
  const [paging, setPaging] = useState<PageFilter>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [viewMemberOfSalesTeamId, setViewMemberOfSalesTeamId] = useState<number | null>(
    null,
  );

  const {
    items: salesTeams,
    totalItems,
    loading,
  } = useSelector<AppState, SalesTeamState>((state) => state.salesTeam);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setFilters((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    setPaging({
      ...paging,
      pageNumber: 0,
    });
  };

  const handleChangePageNumber = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPaging({ ...paging, pageNumber: newPage });
  };

  const handleChangePageSize = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPaging({
      ...paging,
      pageSize: +event.target.value,
      pageNumber: 0,
    });
  };

  useEffect(() => {
    dispatch(fetchSalesTeamList(filters, paging));
    return function cleanUp() {
      dispatch(clearSalesTeam());
    };
  }, [dispatch, paging, filters]);

  const addNewSalesTeam = (): void => {
    history.push('/settings/salesTeams/form');
  };

  const editSalesTeam = (id: SalesTeam['id']): void => {
    history.push(`/settings/salesTeams/form/${id}`);
  };

  return (
    <PageContainer>
      <PageTitle>Sales Team</PageTitle>
      <div className={classes.filterContainer}>
        <SearchField
          filterKey={'Name.startsWith'}
          filters={filters}
          label="Name"
          onChange={handleChangeFilter}
        />
        <SearchField
          filterKey={'Owner.FullNameWithUserName.Contains'}
          filters={filters}
          label="Owner"
          onChange={handleChangeFilter}
        />
        <Grid item xs={12} md={3} lg={2}>
          <SearchDropdownField
            label="Status"
            filterKey={'Active.EqualsTo'}
            filters={filters}
            onChange={handleChangeFilter}
            id="status"
          >
            <MenuItem value={''}>All</MenuItem>
            <MenuItem value={'true'}>Active</MenuItem>
            <MenuItem value={'false'}>Inactive</MenuItem>
          </SearchDropdownField>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addNewSalesTeam}
          size="large"
          disabled={isDisabled}
        >
          <AddIcon className={classes.iconButton} />
          New Sales Team
        </Button>
      </div>
      <Table className={classes.tableContainer}>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Group</TableCell>
            <TableCell>Team Name</TableCell>
            <TableCell>Team Owner</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableLoading isLoading={loading} colSpan={5}>
            {salesTeams.map((salesTeam, index) => (
              <TableRow key={salesTeam.id} style={{ height: 35 }}>
                <TableCell>{index + paging.pageSize * paging.pageNumber + 1}</TableCell>
                <TableCell>{salesTeam.salesGroup?.name}</TableCell>
                <TableCell>{salesTeam.name}</TableCell>
                <TableCell>{salesTeam.owner?.fullNameWithUserName}</TableCell>
                <TableCell>
                  {salesTeam.active ? (
                    <Chip
                      size="small"
                      label="Active"
                      color="primary"
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Inactive"
                      color="secondary"
                      variant="outlined"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title={'Edit'}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => editSalesTeam(salesTeam.id)}
                        disabled={isDisabled}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={'View Members'}>
                    <IconButton
                      size={'small'}
                      onClick={() => setViewMemberOfSalesTeamId(salesTeam.id!)}
                    >
                      <PeopleIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableLoading>
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={totalItems}
        rowsPerPage={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
      />
      {viewMemberOfSalesTeamId !== null && (
        <SalesTeamMemberDialog
          salesTeamId={viewMemberOfSalesTeamId!}
          handleClose={() => {
            setViewMemberOfSalesTeamId(null);
          }}
          open={viewMemberOfSalesTeamId !== null}
        />
      )}
    </PageContainer>
  );
};

export default SalesTeamList;
