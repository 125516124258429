import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import debouncePromise from 'debounce-promise';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { HTMLAttributes, useCallback } from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { Omit } from '@material-ui/types';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import {
  createStyles,
  emphasize,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

export interface AsyncAutoCompleteProps<Type = any> {
  name: string;
  placeholder: string;
  handleChange: (value: Type) => void;
  promiseOptions: (inputValue: any) => Promise<any>;
  value: Type;
  isDisabled?: boolean;
  className?: string;
  getOptionLabel: (option: Type) => string;
  getOptionValue: (option: Type) => string;
  error?: boolean;
  helperText?: string;
  label?: string;
  isMulti?: boolean;
  creatable?: boolean;
}

export interface AsyncAutoCompleteOption {
  label: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
      minWidth: 290,
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
      minWidth: '10em',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
    },
    paper: {
      position: 'absolute',
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
    whiteSpace: {
      whiteSpace: 'pre',
    },
  }),
);

function NoOptionsMessage(props: NoticeProps<any>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<any>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      margin={'dense'}
      autoComplete={'off'}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<any>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: 'pre',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<any>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<any>, 'innerProps'>>;

function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color={selectProps.TextFieldProps.error ? 'error' : 'textSecondary'}
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<any>) {
  const classes = useStyles();
  return (
    <Typography
      className={`${props.selectProps.classes.singleValue} ${classes.whiteSpace}`}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<any>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: MenuProps<any>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const IntegrationReactSelect: React.FC<AsyncAutoCompleteProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    name,
    placeholder,
    promiseOptions,
    handleChange,
    value,
    getOptionLabel,
    getOptionValue,
    error,
    helperText,
    label,
    creatable = false,
    ...other
  } = props;

  const debouncePromiseOptions = useCallback(debouncePromise(promiseOptions, 300), [
    promiseOptions,
  ]);

  const isNewCreatableValue = creatable && !value.name && value.label;
  const creatableValue = isNewCreatableValue
    ? {
        ...value,
        label: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <NewReleasesIcon color="secondary" style={{ marginRight: '5px' }} />
            <span style={{ whiteSpace: 'pre' }}>{value.label}</span>
          </div>
        ),
      }
    : value;

  return (
    <>
      {!creatable && (
        <AsyncSelect
          cacheOptions
          defaultOptions
          classes={classes}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: theme.zIndex.tooltip }),
          }}
          inputId={name}
          TextFieldProps={{
            // label: !!label ? label : name,
            InputLabelProps: {
              htmlFor: name,
              shrink: true,
            },
            error,
            helperText,
          }}
          placeholder={placeholder}
          loadOptions={debouncePromiseOptions}
          components={components}
          value={value}
          onChange={handleChange}
          isClearable={true}
          menuPlacement="auto"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          {...other}
        />
      )}
      {creatable && (
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          classes={classes}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: theme.zIndex.tooltip }) }}
          inputId={name}
          TextFieldProps={{
            // label: !!label ? label : name,
            InputLabelProps: {
              htmlFor: name,
              shrink: true,
            },
            error,
            helperText,
          }}
          placeholder={placeholder}
          loadOptions={debouncePromiseOptions}
          components={components}
          value={creatableValue}
          onChange={handleChange}
          isClearable={true}
          menuPlacement="auto"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          formatCreateLabel={(userInput) => `Search for ${userInput}`}
          {...other}
        />
      )}
    </>
  );
};

export default IntegrationReactSelect;
