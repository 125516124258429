import {
  Badge,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  AssignmentReturned as AssignmentReturnedIcon,
} from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { ITask } from '../../../models/task.model';
import { AuthState } from '../../../state/auth';
import { AppState } from '../../../state/configureStore';
import { fetchRelatedSubtaskListByTaskId } from '../../../state/subtask';
import {
  clearTask,
  createTask,
  fetchTask,
  TaskState,
  updateTask,
} from '../../../state/task';
import { fetchTaskAttachmentListByTaskId } from '../../../state/taskAttachment';
import { fetchTaskItemListByTaskId } from '../../../state/taskItem';
import { Loading, PageTitle, Show } from '../../components';
import LogWorkDialog from './logWork/LogWorkDialog';
import LogWorkList from './logWork/LogWorkList';
import { SubtaskAutoCreateDialog, SubtaskList } from './subtask';
import { TaskActivityComment, TaskActivityList } from './taskActivity';
import { TaskAttachment } from './taskAttachment';
import useStyles from './taskForm.style';
import { TaskItemList } from './taskItem';
import { exportTaskAsExcel } from '../../../services/taskService';

const formPageUrl = `/tasks/form`;

const TaskSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  description: Yup.string(),
  dueDate: Yup.date().required('Due Date is required').nullable(true),
});

interface TaskFormRouteParamsProps {
  id: string;
  subtaskId: string;
}

interface TaskFormProps extends RouteComponentProps<TaskFormRouteParamsProps> {}

const TaskForm: React.FC<TaskFormProps> = (props) => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { id: paramsId },
  } = match;
  const isUpdateMode = paramsId !== undefined;
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [openLogWorkDialog, setOpenLogWorkDialog] = useState(false);
  const [openSubtaskAutoCreateDialog, setOpenSubtaskAutoCreateDialog] = useState(false);
  const { fullName, userId, user } = useSelector<AppState, AuthState>(
    (state) => state.auth,
  );
  const {
    item: {
      id,
      subject = '',
      taskNumber = '',
      description = '',
      dueDate = null,
      requester = user,
      requesterId = userId,
      createdAt = null,
    },
    loading,
    totalSubtasks,
    totalTaskAttachments,
    totalTaskItems,
  } = useSelector<AppState, TaskState>((state) => state.task);

  const redirectToTaskList = () => {
    if (history.length === 1) {
      history.replace('/tasks');
    } else {
      history.goBack();
    }
  };

  const afterCreateTaskCallback = () => {
    setOpenSubtaskAutoCreateDialog(true);
  };

  const afterCreateSubtaskAutoCallback = () => {
    history.push(`${formPageUrl}/${id}`);
  };

  const handleSubmit = (values: ITask, actions: FormikHelpers<ITask>) => {
    if (values.dueDate! && !moment(values.dueDate!, 'YYYY-MM-DD').isValid()) {
      actions.setFieldError('dueDate', 'Due date was not in a correct format');
    } else {
      if (isUpdateMode) {
        dispatch(updateTask({ values, callback: afterCreateTaskCallback }));
      } else {
        dispatch(createTask({ values, callback: afterCreateTaskCallback }));
      }
      actions.setSubmitting(false);
    }
  };

  const onClickExport = async () => {
    try {
      await exportTaskAsExcel(id!);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isUpdateMode) {
      dispatch(fetchTask(+paramsId));
    } else {
      dispatch(clearTask());
    }

    return function cleanUp() {
      dispatch(clearTask());
    };
  }, [paramsId, dispatch, isUpdateMode]);

  useEffect(() => {
    if (paramsId) {
      dispatch(fetchRelatedSubtaskListByTaskId(+paramsId));
      dispatch(fetchTaskItemListByTaskId(+paramsId));
      dispatch(fetchTaskAttachmentListByTaskId(+paramsId));
    }
  }, [dispatch, paramsId]);

  return (
    <>
      <LogWorkDialog
        open={openLogWorkDialog}
        onClose={() => {
          setOpenLogWorkDialog(false);
        }}
        subtaskId={null}
        taskId={id}
        title="Log Duration Task"
      />
      <SubtaskAutoCreateDialog
        open={openSubtaskAutoCreateDialog}
        handleClose={() => {
          setOpenSubtaskAutoCreateDialog(false);
          afterCreateSubtaskAutoCallback();
        }}
      />
      <Card className={classes.root}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            id,
            subject,
            description,
            dueDate,
            requester,
            requesterId,
          }}
          validationSchema={TaskSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          isInitialValid={true}
        >
          {({ values, handleChange, errors, submitForm, setFieldValue }) => {
            return (
              <Form>
                {/* <PageTitle>
                  {isUpdateMode ? `Update Task ${taskNumber}` : `New Task`}
                </PageTitle> */}
                <Typography variant="h5" className={classes.title}>
                  {isUpdateMode ? `Update Task ${taskNumber}` : `New Task`}
                </Typography>
                <Grid item xs={12}>
                  <div className={classes.buttonGroup}>
                    <Button
                      color="default"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        setOpenLogWorkDialog(true);
                      }}
                      disabled={!isUpdateMode}
                      startIcon={<AssignmentIcon />}
                    >
                      LOG WORK
                    </Button>
                    {/* <Button
                      color="default"
                      variant="contained"
                      className={classes.button}
                      onClick={onClickExport}
                      disabled={!isUpdateMode}
                      startIcon={<AssignmentReturnedIcon />}
                    >
                      Export
                    </Button> */}
                    <Button
                      color="default"
                      variant="contained"
                      className={classes.button}
                      onClick={redirectToTaskList}
                      startIcon={<CloseIcon />}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        submitForm();
                      }}
                      startIcon={<SaveIcon />}
                    >
                      {!isUpdateMode ? `Create` : `Update`}
                    </Button>
                  </div>
                </Grid>
                <Loading isLoading={loading}>
                  <CardContent>
                    <TextField
                      name="subject"
                      type="input"
                      label="Subject"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={values.subject}
                      onChange={handleChange}
                      error={!!errors.subject}
                      helperText={errors.subject}
                    />
                    <TextField
                      name="description"
                      type="input"
                      label="Description"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={values.description}
                      onChange={handleChange}
                      error={!!errors.description}
                      helperText={errors.description}
                      multiline
                      rows="5"
                    />
                    <TextField
                      name="Requester"
                      type="input"
                      label="Requester"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={requester ? `${requester.fullName}` : `${fullName}`}
                      disabled
                    />
                    <Grid item xs={12}>
                      <Show show={createdAt !== null}>
                        <KeyboardDatePicker
                          fullWidth={true}
                          margin="normal"
                          label="Created Date"
                          format="yyyy-MM-dd"
                          value={createdAt}
                          onChange={() => {}}
                          disabled
                        />
                      </Show>
                    </Grid>
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        fullWidth={true}
                        margin="normal"
                        label="Due Date"
                        format="yyyy-MM-dd"
                        value={values.dueDate}
                        onChange={(selectedDate, selectedStringDate) => {
                          setFieldValue('dueDate', selectedStringDate);
                        }}
                        error={!!errors.dueDate}
                        helperText={errors.dueDate}
                      />
                    </Grid>
                  </CardContent>
                </Loading>
              </Form>
            );
          }}
        </Formik>
      </Card>
      {id !== undefined && (
        <Card className={classes.root}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newIndex) => setTabIndex(newIndex)}
            variant="scrollable"
            className={classes.tab}
          >
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  color="primary"
                  badgeContent={totalSubtasks}
                >
                  Subtasks
                </Badge>
              }
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  color="primary"
                  badgeContent={totalTaskItems}
                >
                  Items
                </Badge>
              }
            />
            <Tab label="Activities" />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  color="primary"
                  badgeContent={totalTaskAttachments}
                >
                  Attachments
                </Badge>
              }
            />
            <Tab label="Log Works" />
          </Tabs>
        </Card>
      )}
      {isUpdateMode && tabIndex === 0 && <SubtaskList smTaskId={+paramsId} />}
      {isUpdateMode && tabIndex === 1 && <TaskItemList smTaskId={id} />}
      {isUpdateMode && tabIndex === 2 && (
        <Card className={classes.activity}>
          <TaskActivityComment smTaskId={+paramsId} />
          <TaskActivityList smTaskId={+paramsId} />
        </Card>
      )}
      {isUpdateMode && tabIndex === 3 && <TaskAttachment smTaskId={+paramsId} />}
      {isUpdateMode && tabIndex === 4 && <LogWorkList smTaskId={+paramsId} />}
    </>
  );
};

export default TaskForm;
