export const baseLinks = [
  {
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    name: 'Sales',
    nestedList: [
      {
        name: 'Responsibility',
        path: '/responsibilities',
      },
      {
        name: 'Assignment',
        nestedList: [
          {
            name: 'Assignment',
            path: '/assignments',
          },
          // {
          //   name: 'Assignment Customer',
          //   path: '/assignments/customers',
          // },
          // {
          //   name: 'Assignment Customer Groups',
          //   path: '/opportunityCustomerGroups',
          // },
          {
            name: 'Assignment Item(Block)',
            path: '/assignments/items',
          },
          // {
          //   name: 'Assignment Approval',
          //   path: '/assignments/approvals',
          // },
        ],
      },
      {
        name: 'Tasks',
        nestedList: [
          {
            name: 'Task',
            path: '/tasks',
          },
          {
            name: 'Subtask List',
            path: '/tasks/subtasks',
          },
          {
            name: 'Subtask Item List',
            path: '/tasks/subtasks/subtaskItems',
          },
          // {
          //   name: 'Subtask Item Art Template List',
          //   path: '/tasks/subtasks/subtaskItemArtTemplates',
          // },
          // {
          //   name: 'Task Item List',
          //   path: '/tasks/taskItems',
          // },
        ],
      },
      {
        name: 'Program Brand Request',
        path: '/programAndBrandRequests',
      },
      {
        name: 'Sales Team Management',
        nestedList: [
          {
            name: 'Sales Group',
            path: '/settings/salesGroups',
          },
          {
            name: 'Sales Team',
            nestedList: [
              {
                name: 'Sales Team',
                path: '/settings/salesTeams',
              },
              {
                name: 'Sales Team Member',
                path: '/settings/salesTeamMembers',
              },
            ],
          },
        ],
      },
      {
        name: 'Working Team Management',
        nestedList: [
          {
            name: 'Working Group',
            path: '/settings/workingGroups',
          },
          {
            name: 'Working Team',
            nestedList: [
              {
                name: 'Working Team',
                path: '/settings/workingTeams',
              },
              {
                name: 'Working Team Member',
                path: '/settings/workingTeamMembers',
              },
            ],
          },
        ],
      },
      {
        name: 'Project Management',
        nestedList: [
          {
            name: 'Project',
            path: '/projects',
          },
        ],
      },
      {
        name: 'Customer Group',
        nestedList: [
          {
            name: 'Customer Group',
            path: '/customerGroups',
          },
          {
            name: 'Customer Group Member',
            path: '/customerGroupMembers',
          },
        ],
      },
      {
        name: 'Program',
        path: '/settings/programs',
      },
      {
        name: 'Brand',
        path: '/settings/brands',
      },
      {
        name: 'Block',
        path: '/settings/blocks',
      },
      {
        name: 'Group Products',
        path: '/settings/eBlockGroupProducts',
      },
      {
        name: 'Materials',
        path: '/settings/eBlockMaterials',
      },
      {
        name: 'Sizes',
        path: '/settings/eBlockSizes',
      },
      {
        name: 'Patterns',
        path: '/settings/eBlockPatterns',
      },
      {
        name: 'EBlocks',
        path: '/settings/eBlocks',
      },
      {
        name: 'EBlocks Art Templates',
        path: '/settings/eBlocksArtTemplates',
      },
    ],
  },
  {
    name: 'Sample & NPD',
    nestedList: [
      {
        name: 'Tasks',
        nestedList: [
          {
            name: 'Task',
            path: '/tasks',
          },
          {
            name: 'Subtask List',
            path: '/tasks/subTasks',
          },
          {
            name: 'Subtask Item List',
            path: '/tasks/subtasks/subtaskItems',
          },
          // {
          //   name: 'Task Item List',
          //   path: '/tasks/taskItems',
          // },
        ],
      },
      {
        name: 'Assignment',
        nestedList: [
          {
            name: 'Assignment Item(Block)',
            path: '/assignments/items',
          },
          // {
          //   name: 'Assignment Approval',
          //   path: '/assignments/approvals',
          // },
        ],
      },
      {
        name: 'Customer Group',
        nestedList: [
          {
            name: 'Customer Group Member',
            path: '/customerGroupMembers',
          },
        ],
      },
      {
        name: 'Program',
        path: '/settings/programs',
      },
      {
        name: 'Brand',
        path: '/settings/brands',
      },
      {
        name: 'Block',
        path: '/settings/blocks',
      },
    ],
  },
  {
    name: 'Cost Estimator',
    nestedList: [
      {
        name: 'Subtask List',
        path: `/tasks/subTasks?SmSubTaskType.Code.EqualsTo=PRICE`,
      },
      {
        name: 'Program',
        path: '/settings/programs',
      },
      {
        name: 'Brand',
        path: '/settings/brands',
      },
      {
        name: 'Block',
        path: '/settings/blocks',
      },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
  },
  {
    name: 'Sample Development',
    nestedList: [
      {
        name: 'Sample Development NL List',
        path: `/sampleDevelopmentNl`,
      },
      {
        name: 'Sample Development HL List',
        path: `/sampleDevelopmentHl`,
      },
      {
        name: 'Sample Development PL List',
        path: `/sampleDevelopmentPl`,
      },
      {
        name: 'Sample Development TL List',
        path: `/sampleDevelopmentTl`,
      },
      {
        name: 'Sample Development WL List',
        path: `/sampleDevelopmentWl`,
      },
    ],
  },
];
