import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputLabel, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export interface BaseSearchDropdownFieldProps {
  id: string;
  label: string;
  onChange: React.ChangeEventHandler<
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
    | { name?: string; value: unknown }
  >;
  filters: any;
  filterKey: string;
}

const useStyles = makeStyles({
  customSelect: {
    '& .MuiOutlinedInput-root': {
      height: '48px', // ปรับความสูงทั้งหมดของ Select
    },
    '& .MuiSelect-select': {
      padding: '10px 10px', // ปรับ padding ให้พอดีกับความสูง 48px
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1565c0',
      borderWidth: '1px',
      boxShadow: '0 0 8px rgba(21, 101, 192, 0.5)', // เพิ่ม box-shadow ฟุ้งตอน focus
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1565c0', // สีขอบเมื่อ hover
      borderWidth: '1px',
    },
  },
});

const SearchDropdownField2: React.FC<BaseSearchDropdownFieldProps> = (props) => {
  const classes = useStyles();
  const { id, label, onChange, filters, filterKey } = props;
  // const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  // useEffect(() => {
  //   setLabelWidth(inputLabel.current!.offsetWidth);
  // }, []);

  return (
    <>
      <Typography variant="body2">Status</Typography>
      <Select
        className={classes.customSelect}
        labelId={id}
        name={filterKey}
        displayEmpty
        value={filters[filterKey]}
        onChange={onChange}
        labelWidth={labelWidth}
        variant="outlined"
        fullWidth
        // style={{ minWidth: '200px' }}
      >
        {props.children}
      </Select>
    </>
  );
};

export default SearchDropdownField2;
